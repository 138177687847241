<template>
    <div class="account-page-wrapper">
        <AccountSidebar v-if="!isMobile" class="account-sidebar" />
        <div class="account-page">
            <slide-x-left-transition :duration="200" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-left-transition>
        </div>
    </div>
</template>

<script>
import AccountSidebar from "@/components/AccountSidebar.vue";
import { SlideXLeftTransition } from "vue2-transitions";
import isMobile from "../../plugins/isMobile";

export default {
    components: { AccountSidebar, SlideXLeftTransition },
    data() {
        return {
            isMobile: isMobile()
        };
    }
};
</script>

<style lang="scss" scoped>
.account-page-wrapper {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    .account-sidebar {
        flex-grow: 0;
    }
    .account-page {
        flex-grow: 1;
    }
}
</style>
