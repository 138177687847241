<template>
    <div class="dashboard-page-wrapper">
        <DashboardSidebar class="dashboard-sidebar" />
        <div class="dashboard-page">
            <slide-x-left-transition :duration="200" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-x-left-transition>
        </div>
    </div>
</template>

<script>
import DashboardSidebar from "@/components/DashboardSidebar.vue";
import { SlideXLeftTransition } from "vue2-transitions";

export default {
    components: { DashboardSidebar, SlideXLeftTransition },
    data() {
        return {
        };
    }
};
</script>

<style lang="scss" scoped>
.dashboard-page-wrapper {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    
    .dashboard-sidebar {
        flex-grow: 0;
    }
    .dashboard-page {
        flex-grow: 1;
    }
}
</style>
