<template>
    <v-card class="mr-6 d-flex justify-space-between flex-column card-shadow account-sidebar" width="90" style="min-width: 90px">
        <div class="d-flex flex-column align-center">
            <v-tooltip right v-for="(item, index) in navListFilter" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <router-link :to="item.link" class="mt-6" :class="{ active: false }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <feather :type="item.icon" class="text-darker" stroke-width="1.5"></feather>
                        </v-btn>
                    </router-link>
                </template>
                <span>{{ item.name }}</span>
            </v-tooltip>
        </div>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'AccountSidebar',
    data() {
        return {
            drawer: null,
            navList: [
                {
                    name: 'Overview',
                    icon: 'home',
                    link: '/dashboard/overview',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                },
                {
                    name: 'My Overdue Forms',
                    icon: 'alert-circle',
                    link: '/dashboard/overdue',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                },
                {
                    name: 'My Upcoming Forms',
                    icon: 'calendar',
                    link: '/dashboard/upcoming',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                },
                {
                    name: 'My Transferred Forms',
                    icon: 'mail',
                    link: '/dashboard/transferred',
                    validRoles: ['Admin', 'Manager', 'Employee', 'GlobalManager']
                }
            ]
        };
    },
    methods: {
        async logout() {
            try {
                await this.authLogout();
                this.showSuccess('Successfully Logged out!');

                this.$router.push({
                    name: 'login'
                });
            } catch (error) {
                this.showError(error);
            }
        },
        ...mapActions('apiAuth', { authLogout: 'logout' }),
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        navListFilter() {
            if (this.authUser) {
                return this.navList.filter((item) => {
                    return item.validRoles.includes(this.authUser.role);
                });
            }

            return this.navList;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>

<style lang="scss" scoped>
.router-link-exact-active button {
    background-color: #f3f4fd;
}
</style>
